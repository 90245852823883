import React from "react";

import { faBuilding } from "@fortawesome/free-solid-svg-icons";

import styles from "@/assets/styles/pages/home.module.scss";
import { getFlooredFixed } from "@/helpers/common";
import SectionTitle from "@/shared/components/section-title/SectionTitle";
import SummaryDataGrid, { ISummaryDataGridHeader } from "@/shared/components/SummaryDataGrid";

interface IFemaleLedSector {
    data: Homepage.SectorAnalysis
}

function FemaleLedSector({ data }: IFemaleLedSector) {
    const summary = data?.["section7.1"];

    const top5FemaleLedHeader: ISummaryDataGridHeader[] = [
        {
            id: "top5",
            heading: "Top 5 female-led sectors",
            ratio: 9,
        },
        {
            id: "uk_perc",
            heading: "%*",
            formatType: "percentage",
            ratio: 3,
        },
    ];

    const bottom5FemaleLedHeader: ISummaryDataGridHeader[] = [
        {
            id: "bottom5",
            heading: "Bottom 5 female-led sectors ",
            ratio: 9,
        },
        {
            id: "uk_perc",
            heading: "%*",
            formatType: "percentage",
            ratio: 3,
        },
    ];

    const top5FemaleLedData = data?.["section7.2"].map((i) => ({
        "top5": i.sicCategory,
        "uk_perc": i.PercentageFemaleLed.toFixed(2),
    }));

    const bottom5FemaleLedData = data?.["section7.3"].map((i) => ({
        "bottom5": i.sicCategory,
        "uk_perc": i.PercentageFemaleLed.toFixed(2),
    }));

    return (
        <div className={styles["female-led-sector"]}>
            <div className="container">
                <div className={`${styles["female-led-sector-main"]} grid-wrapper`}>
                    <div className="row">
                        <div className="col-12 col-xl-12">
                            <SectionTitle
                                iconProps={{
                                    icon: faBuilding,
                                    background: "#00A7B7",
                                }}
                                title={"Female-led sector analysis"}
                                summary={summary}
                            />
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6">
                            <div className={`${styles["female-led-sector-main-sdg"]}`}>
                                <SummaryDataGrid
                                    data={top5FemaleLedData}
                                    header={top5FemaleLedHeader}
                                    fontClass="large"
                                    background="#00A7B7"
                                    spacing="small" />
                            </div>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6">
                            <div className={`${styles["female-led-sector-main-sdg"]}`}>
                                <SummaryDataGrid
                                    data={bottom5FemaleLedData}
                                    header={bottom5FemaleLedHeader}
                                    fontClass="large"
                                    background="#FD7278"
                                    spacing="small" />
                            </div>
                        </div>
                        <div className="col-12 col-xl-12">
                            <div className={styles["female-led-sector-main-active-uk"]}>*Percentage of Female-led companies</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FemaleLedSector;
